<template>
  <Row>
    <i-col span="6" class="bg-white">
      <Card style="width:320px">
        <div style="text-align:center">
          <img src="../assets/logo.png" />
          <h3>A high quality UI Toolkit based on Vue.js</h3>
        </div>
      </Card>
    </i-col>
    <i-col span="6" class="bg-white">
      <Card style="width:320px">
        <div style="text-align:center">
          <img src="../assets/logo.png" />
          <h3>A high quality UI Toolkit based on Vue.js</h3>
        </div>
      </Card>
    </i-col>
    <i-col span="6" class="bg-white">
      <Card style="width:320px">
        <div style="text-align:center">
          <img src="../assets/logo.png" />
          <h3>A high quality UI Toolkit based on Vue.js</h3>
        </div>
      </Card>
    </i-col>
    <i-col span="6" class="bg-white">
      <Card style="width:320px">
        <div style="text-align:center">
          <img src="../assets/logo.png" />
          <h3>A high quality UI Toolkit based on Vue.js</h3>
        </div>
      </Card>
    </i-col>
  </Row>
</template>
<script>
export default {
  name: "mpsetting",
  data() {
    return {
      tData: [],
    };
  },
};
</script>

<style lang="less" scoped="scoped">
</style>